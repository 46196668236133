<template>
  <div class="page document-page">
    <BreadcrumbsComponent :title="title" />
    <div class="page__inner">
      <SectionComponent>
        <div class="document-page__inner">
          <EditorJSComponent v-if="text" :text="JSON.parse(text)" />
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import DOCUMENT_PAGE from "@/graphql/pages/DocumentPage.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";

export default {
  name: "DocumentPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DOCUMENT_PAGE,
      })
      .then(({ data }) => {
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      });
  },
  computed: {
    text() {
      let id = parseInt(this.$route.params.id);
      let types = this.$store.state._types;

      switch (id) {
        case types.ORDERING_RULES: {
          return this.$store.state.global.feedback.ordering_rules;
        }
        case types.HELP: {
          return this.$store.state.global.feedback.help;
        }
        case types.REFUND: {
          return this.$store.state.global.feedback.refund;
        }
        case types.PAYMENT_METHOD: {
          return this.$store.state.global.feedback.payment_method;
        }
        case types.COOPERATION: {
          return this.$store.state.global.feedback.cooperation;
        }
        case types.OFFER: {
          return this.$store.state.global.feedback.offer;
        }
        case types.TERMS: {
          return this.$store.state.global.feedback.terms;
        }
        case types.POLICY: {
          return this.$store.state.global.feedback.policy;
        }
        case types.PERSONAL_DATA: {
          return this.$store.state.global.feedback.personal_data;
        }
        case types.FOR_BUSINESS: {
          return this.$store.state.global.feedback.for_business;
        }
      }
      return null;
    },
    title() {
      let id = parseInt(this.$route.params.id);
      let types = this.$store.state._types;

      switch (id) {
        case types.ORDERING_RULES: {
          return "Правила оформления заказа";
        }
        case types.HELP: {
          return "Помощь";
        }
        case types.REFUND: {
          return "Возврат";
        }
        case types.PAYMENT_METHOD: {
          return "Способы оплаты";
        }
        case types.COOPERATION: {
          return "Сотрудничество";
        }
        case types.OFFER: {
          return "Публичная оферта";
        }
        case types.TERMS: {
          return "Условия использования";
        }
        case types.POLICY: {
          return "Политика конфиденциальности";
        }
        case types.PERSONAL_DATA: {
          return "Обработка персональных данных";
        }
        case types.FOR_BUSINESS: {
          return "Для бизнеса";
        }
      }
      return "Документа не существует";
    },
  },
  components: {
    SectionComponent,
    BreadcrumbsComponent,
    EditorJSComponent,
  },
};
</script>

<style lang="stylus">
.document-page {

  &__inner {
    max-width 857px
  }
}
</style>
